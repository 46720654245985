<template>
  <div class="dx-viewport">
    <div v-if="items.length">
      <b-row>
        <b-col md="12">
          <div class="form-group mt-3">
            <div class="checkbox-inline">
              <label class="checkbox checkbox-primary">
                <input type="checkbox" v-model="personalized" />
                <span></span>¿Quiere personalizar su reporte?</label
              >
            </div>
          </div>
        </b-col>

        <b-col v-if="!personalized">
          <DxDataGrid
            :data-source="items"
            :show-borders="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="true"
            :columnAutoWidth="true"
            :allow-column-resizing="true"
            :allow-column-reordering="true"
            :ref="gridRefName"
            id="settlementTable"
          >
            <DxExport :enabled="true" />
            <DxColumnChooser
              emptyPanelText="Arrastre una columna aquí para ocultar"
              :enabled="true"
              title="Arrastre columna aquí..."
            />
            <DxColumnFixing :enabled="true" />
            <DxGroupPanel
              :visible="false"
              emptyPanelText="Arrastre una columna aquí para agrupar"
            />
            <DxSearchPanel
              :visible="true"
              :width="240"
              placeholder="Buscar..."
            />
            <DxPaging :page-size="20" />
            <DxPager
              :visible="true"
              infoText="Página {0} de {1} ({2} registros)"
              :allowed-page-sizes="[10, 20, 30, 50]"
              display-mode="full"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
            />
            <DxHeaderFilter :visible="true" :allowSearch="true" />
            <DxFilterRow :visible="true">
              <DxOperationDescriptions
                equal="Igual a"
                notEqual="Diferente de"
                startsWith="Empieza con"
                endsWith="Termina con"
                contains="Contiene"
                between="Entre"
                notContains="No contiene"
                lessThanOrEqual="Menor o igual"
                greaterThanOrEqual="Mayor o igual"
                lessThan="Menor que"
                greaterThan="Mayor que"
              />
            </DxFilterRow>
            <DxColumn
              caption="Turno"
              alignment="center"
              data-field="turno.observacion"
            />
            <DxColumn data-field="base.cliente" caption="Contratista" />
            <DxColumn
              data-field="brigada"
              caption="Cód. brigada"
              width="130"
              alignment="center"
            />
            <DxColumn
              data-field="tecnico.nombre"
              caption="Tecnico"
              width="130"
              alignment="center"
            />

            <DxColumn data-field="placa" caption="Vehículo" />
            <DxColumn
              data-field="vehiculo.tipo.descripcion"
              caption="Tipo Veh."
            />
            <DxColumn
              data-field="fecha_inicio"
              caption="Fecha inicio"
              data-type="date"
              format="yyyy/MM/dd, HH:mm:ss"
              width="150"
            />
            <DxColumn
              data-field="fecha_fin"
              caption="Fecha fin"
              data-type="date"
              format="yyyy/MM/dd, HH:mm:ss"
              width="150"
            />

            <DxToolbar>
              <DxItem location="after" template="refreshTemplate" />
              <DxItem name="columnChooserButton" />
              <DxItem name="exportButton" />
              <DxItem name="groupPanel" />
              <DxItem name="searchPanel" />
            </DxToolbar>
            <template #refreshTemplate>
              <DxButton icon="refresh" @click="refreshDataGrid()" />
            </template>
          </DxDataGrid>
        </b-col>
        <b-col v-if="personalized">
          <DxPivotGrid
            id="pivotgrid"
            ref="grid"
            :data-source="items"
            :allow-sorting-by-summary="true"
            :allow-filtering="true"
            :show-borders="true"
            :show-column-grand-totals="false"
            :show-row-grand-totals="false"
            :show-row-totals="false"
            :show-column-totals="false"
            :allow-expand-all="true"
            alinment="center"
            :texts="MainText"
          >
            <DxExport :enabled="true" />
            <DxFieldChooser
              title="Genera tu reporte"
              :layout="2"
              :texts="pivotText"
              :allowSearch="true"
              :enabled="true"
              :height="400"
            />
            <DxScrolling mode="standar" />
          </DxPivotGrid>
        </b-col>
      </b-row>
    </div>
    <b-row v-else>
      <b-col>
        <div v-if="!loading">
          <div class="text-center">
            <img src="assets/img/empty.png" alt="Empty" />
            <p class="text-muted">No hay información para mostrar</p>
          </div>
        </div>
        <div class="text-center" v-else>
          <img src="assets/img/loader.gif" alt="Loading..." />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style>
#settlementTable {
  max-height: 700px !important;
}
#pivotgrid {
  min-height: 400px !important;
}
</style>
<script>
import {
  DxDataGrid,
  DxPaging,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxOperationDescriptions,
  DxExport,
  DxGroupPanel,
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxToolbar,
  DxItem,
} from "devextreme-vue/data-grid";
import {
  DxPivotGrid,
  DxFieldChooser,
  DxScrolling,
} from "devextreme-vue/pivot-grid";
import { DxButton } from "devextreme-vue/button";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import axios from "axios";
export default {
  components: {
    DxPivotGrid,
    DxFieldChooser,
    DxScrolling,
    DxDataGrid,
    DxPaging,
    DxSearchPanel,
    DxOperationDescriptions,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxExport,
    DxGroupPanel,
    DxColumn,
    DxColumnChooser,
    DxColumnFixing,
    DxButton,
    DxToolbar,
    DxItem,
  },
  data() {
    return {
      personalized: false,
      gridRefName: "dataGrid",
      loading: false,
      items: [],
      pageSizes: [5, 10, 15, 30, 50],
      pivotText: {
        allFields: "Todos los campos",
        columnFields: "Columnas",
        dataFields: "Valores",
        filterFields: "Campos de filtro",
        rowFields: "Filas",
      },
      MainText: {
        noData: "No hay información",
        grandTotal: "Total",
        collapseAll: "Desplegar todo",
        expandAll: "Expandir todo",
        exportToExcel: "Exportar a Excel",
        removeAllSorting: "Quitar orden",
        showFieldChooser: "Mostrar selector de campo",
        sortColumnBySummary: "Ordenar {0} por esta columna",
        sortRowBySummary: "Ordenar {0} por esta fila",
      },
      texts: {
        editRow: "Editar",
        saveRowChanges: "Guardar",
        saveAllChanges: "Guardar cambios",
        deleteRow: "Eliminar",
        cancelRowChanges: "Cancelar",
        confirmDeleteTitle: "Información",
        confirmDeleteMessage: "¿Realmente desea eliminar el registro?",
        cancelAllChanges: "Descartar cambios",
      },
    };
  },
  mounted() {
    this.loadSettlement();
  },
  methods: {
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Information");

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `${this.$route.name}.xlsx`
          );
        });
      });
      e.cancel = true;
    },
    loadSettlement() {
      this.loading = true;
      this.items = [];
      axios
        .get(`/turns-cumplimiento`)
        .then((res) => {
          this.items = res.data;
          this.loading = false;
          res.data.map((el) => {
            if (el.turno) {
              el.turno.observacion = el.turno.observacion
                .split("-")
                .join(" y ")
                .split("|")
                .join(" a ");
            } else {
              el.turno = { observacion: "NO PROGRAMADO" };
            }
          });
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    refreshDataGrid() {
      this.loadSettlement();
    },
  },
};
</script>
